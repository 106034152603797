import "./ProcessNew.css"

import process1 from "../../../Assets/Process/1.png"
import process2 from "../../../Assets/Process/2.png"
import process3 from "../../../Assets/Process/3.png"
import process4 from "../../../Assets/Process/4.png"
import process5 from "../../../Assets/Process/5.png"

const process = [
    {
        id: 1,
        title: "Concept and Script Development",
        description: "Start with a well-developed idea and place it in a script format describing scenes, dialogues, and key moments.",
        image: process1
    },
    {
        id: 2,
        title: "Storyboarding",
        description: "Use this script and do rough sketches, also called storyboards, to show the flow of your animation. Stick figures are great; this is all about sequence.",
        image: process2,
    },
    {
        id: 3,
        title: "Character and Background Design",
        description: "Create your characters with their personality, expression, and movements; design the places, whether it be a cozy room or a magical forest, they are going to be in.",
        image: process3,
    },
    {
        id: 4,
        title: "Animation and Keyframing",
        description: "Clean up your sketches with neat line-art, color them, and add shades or gradients to give it depth and the final touch to your animation.",
        image: process4,
    },
    {
        id: 5,
        title: "Audio Integration",
        description: "We amplify your message with professional voice overs in multiple languages that make every word resonate with your audience",
        image: process1,
    },
    {
        id: 6,
        title: "Final Rendering and Editing",
        description: "Add music to the background, sound effects, and voiceovers in your animation. Let all these be in sync with each other, adding glowing lights to give an extra edge.",
        image: process5,
    },

]

export default function ProcessNew() {
    return (
        <section id="process">
            <div className="process-container container1">
                <div className="title">
                    <h2>Our Process</h2>
                </div>
                <div className="process-grid">
                    {process.map((ele, i) => {
                        return (
                            <div className="process-grid-card">
                                <span className="si-no">{i+1}.</span>
                                <div className="process-image">
                                    <img src={ele.image} alt={ele.title} />
                                </div>
                                <div className="process-content">
                                        <h1>{ele.title}</h1>
                                        <p>{ele.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}