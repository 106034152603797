import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "./Services.css";
import Splide from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import '@splidejs/splide/dist/css/splide.min.css';

import client1 from "../../../Assets/Clients/abudhabi-culture-tourism.png"
import client2 from "../../../Assets/Clients/abudhabi-police.png"
import client3 from "../../../Assets/Clients/abudhabi-sewage.png"
import client4 from "../../../Assets/Clients/agitha.png"
import client5 from "../../../Assets/Clients/australia-post.png"
import client6 from "../../../Assets/Clients/become.png"
import client7 from "../../../Assets/Clients/broccoli.png"
import client8 from "../../../Assets/Clients/canon.png"
import client9 from "../../../Assets/Clients/channel.png"
import client10 from "../../../Assets/Clients/dahua.png"
import client11 from "../../../Assets/Clients/emirates-nbd.png"
// import client12 from "../../../Assets/Clients/emirates.png"
import client13 from "../../../Assets/Clients/french-embassy.png"
import client14 from "../../../Assets/Clients/huawei.png"
import client15 from "../../../Assets/Clients/marhaba.png"
import client16 from "../../../Assets/Clients/mok.png"
// import client17 from "../../../Assets/Clients/mok.png"
import client18 from "../../../Assets/Clients/neom.png"
import client19 from "../../../Assets/Clients/pmi.png"
import client20 from "../../../Assets/Clients/reyl.png"
import client21 from "../../../Assets/Clients/rolex.png"
import client22 from "../../../Assets/Clients/rpm.png"
import client23 from "../../../Assets/Clients/saudi-aramco.png"
import client24 from "../../../Assets/Clients/shazam.png"
import client25 from "../../../Assets/Clients/siemens.png"
import client26 from "../../../Assets/Clients/snapchat.png"
import client27 from "../../../Assets/Clients/toshiba.png"
import client28 from "../../../Assets/Clients/yamaha.png"

const clients = [
  { img: client1, },
  { img: client2, },
  { img: client3, },
  { img: client4, },
  { img: client5, },
  { img: client6, },
  { img: client7, },
  { img: client8, },
  { img: client9, },
  { img: client10, },
  { img: client11, },
//   { img: client12, },
  { img: client13, },
  { img: client14, },
  { img: client15, },
  { img: client16, },
  // { img: client17 },
  { img: client18 },
  { img: client19 },
  { img: client21 },
  { img: client22 },
  { img: client23 },
  { img: client24 },
  { img: client25 },
  { img: client26 },
  { img: client27 },
  { img: client28 },
];


const serviceTypes = [
    {
        id: 1,
        title: "Explainer Videos",
        description: "Simple, clear, and engaging. Our 2D explainer video services are perfect for breaking down complex ideas with minimal visuals. Think flat graphics, clean animations, and straightforward storytelling that delivers your message with ease."
    },
    {
        id: 2,
        title: "Storytelling Testimonial Videos",
        description: "Add animated characters and scenes to real-life testimonials. With 2D storytelling services, we transform client stories into engaging narratives that feel personal yet visually creative."
    },
    {
        id: 3,
        title: "Educational Videos",
        description: "From animated chalkboards to lively diagrams, 2D animation production makes learning visual and fun. Perfect for explaining concepts or guiding viewers step by step through new ideas, our work stands out among 2D animation studios in Dubai."
    },
    {
        id: 4,
        title: "Trade Show Videos",
        description: "2D animation shines on the big screen with bold, colorful visuals that catch attention in noisy environments. Whether it’s looping animations or infographics, our 2D animation house ensures you’ll be the most memorable booth at any trade show."
    },
    {
        id: 5,
        title: "Live Action Videos",
        description: "Enhance real-world footage with animated overlays, captions, or characters in a 2D animation style. This mix adds personality, making your live-action content approachable and fun, all with the touch of an experienced 2D animation agency."
    },
    {
        id: 6,
        title: "Social Video Ads",
        description: "Flat, colorful designs and snappy animations make for scroll-stopping social media ads. As a leading 2D animation company, we create visuals that grab attention and deliver your message in seconds while feeling light and shareable."
    },
    {
        id: 7,
        title: "Video Case Studies",
        description: "Showcase your success stories with 2D animation video production that guides viewers through the journey. Animated graphs, charts, and storytelling make even the most complex achievements easy to understand."
    },
    // {
    //     id: 8,
    //     title: "Video Courses",
    //     description: "Create dynamic lessons with 2D animations featuring animated diagrams, character-led tutorials, and visually appealing transitions. These videos are perfect for making even the driest topics feel fresh and engaging."
    // },
    {
        id: 8,
        title: "Brand Videos",
        description: "Introduce your brand with playful characters, sleek transitions, and a unique 2D animation studio style that shows off your personality. With rabbit and carrot, your brand will stay approachable and memorable."
    },
    {
        id: 9,
        title: "Video Retainer",
        description: "Need a steady stream of animated content? Our 2D animation video production services keep your visuals fresh with tailored designs that suit your brand and stay on trend. Partner with the best 2D animation studios to keep your content ahead of the curve."
    } 
];


export default function Services({size}) {

    const [currentIndex, setCurrentIndex] = useState(0);

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setCurrentIndex((prevIndex) => (prevIndex + 1) % service.serviceTypes.length);
    //     }, 2000); // Change every 2 seconds
    //     return () => clearInterval(interval); // Cleanup on unmount
    // }, []);

    const textVariants = {
        initial: {
            y: 200,
            opacity: 0,
        },
        animate: {
            y: 0,
            opacity: 1,
            transition: {
                duration: 1,
                ease: "easeOut",
                staggerChildren: 0.3, // Stagger the children by 0.3s
            },
        },
      };
    
      const childVariants = {
          initial: { y: 100, opacity: 0 },
          animate: { y: 0, opacity: 1, transition: { duration: 0.6 } },
      };

    const settings = {
        // customPaging: function(i) {
        //   return (
        //     <a>
        //       <img 
        //         src={clientImages[i]} 
        //         alt={`Client ${i + 1}`} 
        //         style={{ width: "20px", height: "20px", objectFit: "cover" }} 
        //     />
        //     </a>
        //   );
        // },
        dots: false,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        beforeChange: (current, next) => setCurrentIndex(next)
      };

      useEffect(() => {
        const splide = new Splide('.splide', {
            type: 'loop',
            drag: 'free',
            focus: 'center',
            perPage: 6,
            autoScroll: {
                speed: 1,
            },
            pagination: false,
            arrows: false,
            breakpoints: {
                1024: {
                  perPage: 4,
                },
                600: {
                  perPage: 3,
                },
                480: {
                    perPage: 2,
                },
              },
        });

        splide.mount({ AutoScroll });
    }, []);

    return (
        <section id="services" className="services">
            {/* <div className="heading-div">
                <h1>
                    <hr />Services
                </h1>
            </div> */}
            <div className="overlay"></div>
            <div className="services-content">
                <motion.div 
                    className="top container1" 
                    variants = {textVariants} 
                    initial="initial" 
                    whileInView="animate" 
                    viewport={{ once: false }}>
                    {/* <motion.div className="header-container"> */}
                        <motion.h1 variants={childVariants} className="heading">2D Animation provides 
                            <motion.div className="service-types" variants={childVariants}>
                            <Slider {...settings}>
                                {serviceTypes.map((ele, i) => {
                                    return (
                                        <div className="types" key={ele.id}>
                                            <h1>{ele.title}</h1>
                                        </div>
                                    )
                                })}
                            </Slider>
                            </motion.div><motion.p variants={childVariants}> that visually convey engaging narratives.</motion.p>
                        </motion.h1>
                    {/* </motion.div> */}
                    <motion.p variants={childVariants}>{serviceTypes[currentIndex].description}</motion.p>
                    <motion.a variants={childVariants} href="#about-contact"><button className="btn">
                        Book A Free Consultation
                    </button></motion.a>
                </motion.div>
                <div className="bottom-overlay"></div>

                <div className="splide">
                    <div className="splide__track">
                        <ul className="splide__list">
                        {clients.map((ele, index) => (
                            <li className="splide__slide" key={index}>
                            <img src={ele.img} alt={`Client ${index + 1}`} className="slider-image" />
                            </li>
                        ))}
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}