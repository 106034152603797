import React, { useRef } from 'react'
import ReactIframe from 'react-iframe'
import {motion, useScroll, useTransform} from "framer-motion"
import "./AboutUs.css"

export default function AboutUs({ size }) {
  const textVariants = {
    initial: {
        y: 100,
        opacity: 0,
    },
    animate: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 1,
            ease: "easeOut",
            staggerChildren: 0.3, // Stagger the children by 0.3s
        },
    },
  };

  const childVariants = {
      initial: { y: 50, opacity: 0 },
      animate: { y: 0, opacity: 1, transition: { duration: 0.6 } },
  };

    const ref = useRef()
    const {scrollYProgress} = useScroll({
        target: ref,
        start: ["start start", "end start"]
    })

  const yBg = useTransform(scrollYProgress, [0, 1], [-500, 500])
  return (
        <section id="about-contact" className='aboutHome container1' ref={ref} >
            <motion.div className='flexSB' style={{yBg}}>
                <div className='left'>
                    <motion.div 
                        variants={textVariants}
                        initial="initial"
                        whileInView="animate"
                        viewport={{ once: false }}
                        className="aboutHeading">
                        <motion.h1 variants={childVariants}>WHO ARE WE?</motion.h1>
                        {/* <motion.h1>3D Animation</motion.h1> */}
                        <motion.h3 variants={childVariants}>{"MAKING YOUR IDEAS COME TO LIFE".toUpperCase()}</motion.h3>
                        <motion.h2 variants={childVariants}>We turn your stories into super cool 2D Animations that are fun to watch.</motion.h2>
                        <motion.p variants={childVariants}>2D Animation helps in bringing your ideas to life on a flat canvas. It’s about creating characters, scenes, and stories that move in a way that feels real- whether it’s a quirky stick figure or a beautifully drawn mascot.<br/><br/>
                        At Rabbit And Carrot, we’ve been turning visions into stunning animations since 2008. As one of the best 2D Animation studios in Dubai, thousands of clients have trusted us to simplify complex ideas, captivate their audience, and help their ROI grow.</motion.p>
                    </motion.div>
                    {/* <div className='items'>
                            <h2>Creative Mastery</h2>
                            <h2>Strategic Impact</h2>
                            <h2>Innovative Solutions</h2>
                    </div> */}
                </div>
                <div className="right">
                        <ReactIframe
                            aria-label='Get A Quote' 
                            frameborder="0"
                            style={{ padding: '20px', border: 'none' }}
                            src='https://forms.zohopublic.com/virtualoffice10491/form/GetAQuote/formperma/lIfmVFP1bp-fpxo8a-1RQEeRi2qrVOvb8-j3CFbWkx0' 
                            width="100%" 
                            height="610px"
                        />
                </div>
                </motion.div>
        </section>
  )
}