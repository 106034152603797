import React, { useState } from 'react';

import "./FullScreenImage"

const FullScreenImage = ({ src }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleFullScreen = () => {
    const elem = document.getElementById('fullScreenImage');
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE/Edge */
      elem.msRequestFullscreen();
    }
    setIsFullScreen(true);
  };

  return (
    <div>
      <img
        id="fullScreenImage"
        src={src}
        alt="Full-screen mode"
        onClick={handleFullScreen}
        style={{
          width: '100%', 
          height: 'auto',
          cursor: 'pointer',
        }}
      />
    </div>
  );
};

export default FullScreenImage;