import React from 'react'
import { motion } from 'framer-motion'
import phoneIcon from "../../../Assets/Common/phone-white.svg"
import chatIcon from "../../../Assets/Common/chat-white.svg"

import "./QuickContact.css"

function QuickContact() {
    const textVariants = {
        initial: {
            y: 50,
            opacity: 0
        },
        animate: {
            y: 0,
            opacity: 1,
            transition: {
            duration: 1,
            staggerChildren: 0.3
            }
        },
      }

    const childVariants = {
        initial: { y: 50, opacity: 0 },
        animate: { y: 0, opacity: 1, transition: { duration: 0.6 } },
    };

  return (
    <div className="quick-contact">
        <div className="overlay"></div>
        <motion.div 
            initial="initial"
            whileInView="animate"
            transition={{
                duration: 1,
                ease: "easeOut",
                delay: 0.2
            }}
            exit="exit"
            viewport={{ once: false, amount: 0.5 }}
            variants={textVariants}
            className="contact-details">
            <motion.div variants={childVariants} className="contact-phone">
                <motion.img src={phoneIcon} alt="" className="phoneIcon"/>
                <motion.a href="tel:+971542226464">
                    <motion.span>CALL US AT</motion.span><br/>
                    +971 542226464
                </motion.a>
            </motion.div>
            <motion.div variants={childVariants}>
                <motion.a href="#about-contact">
                    <motion.button className="btn button-touch2">Request a Consultation
                        <span className="line">
                            <span className="circle"></span>
                        </span>
                    </motion.button>
                </motion.a>
            </motion.div>
            <motion.div variants={childVariants} className="contact-chat">
                <motion.img src={chatIcon} alt="" className="chatIcon"/>
                <motion.a href="#about-contact">
                    <motion.span>CLICK HERE FOR</motion.span><br/>
                    LIVE CHAT
                </motion.a>
            </motion.div>
        </motion.div>
    </div>
  )
}

export default QuickContact