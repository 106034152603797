import React from 'react'
import "./Recognitions.css"
import { motion } from 'framer-motion'
// import Counter from './Counter'

// import turnaround from "../../../Assets/Common/trunaround.svg"

import img1 from "../../../Assets/Recognitions/engineering.png"
import img2 from "../../../Assets/Recognitions/construction.png"
import img3 from "../../../Assets/Recognitions/technology.png"
import img4 from "../../../Assets/Recognitions/automotive.png"
// import img5 from "../../../Assets/Recognitions/catalogues.png"
import img6 from "../../../Assets/Recognitions/non-profit.png"
import img7 from "../../../Assets/Recognitions/social.png"
import img8 from "../../../Assets/Recognitions/education.png"
// import img9 from "../../../Assets/Recognitions/resources.png"
import img10 from "../../../Assets/Recognitions/sports.png"
import img11 from "../../../Assets/Recognitions/financial.png"
import img12 from "../../../Assets/Recognitions/insurance.png"
import img13 from "../../../Assets/Recognitions/consultation.png"
// import img14 from "../../../Assets/Recognitions/architectural.png"
import img15 from "../../../Assets/Recognitions/food.png"
// import img16 from "../../../Assets/Recognitions/medicine.png"
import img17 from "../../../Assets/Recognitions/health1.png"
import img18 from "../../../Assets/Recognitions/travel.png"
// import img19 from "../../../Assets/Recognitions/matrimony.png"
// import img20 from "../../../Assets/Recognitions/art.png"
import img21 from "../../../Assets/Recognitions/communication.png"
import img22 from "../../../Assets/Recognitions/entertainment.png"
// import img23 from "../../../Assets/Recognitions/environment.png"
import img24 from "../../../Assets/Recognitions/fashion.png"
// import img25 from "../../../Assets/Recognitions/spa.png"
// import img26 from "../../../Assets/Recognitions/children.png"
// import img27 from "../../../Assets/Recognitions/craft.png"
import img28 from "../../../Assets/Recognitions/music.png"
import img29 from "../../../Assets/Recognitions/navigation.png"
import img30 from "../../../Assets/Recognitions/news.png"


// const recognitionHead = [
//   "Engineering", "Constrution", "Technology", "Automotive", "Catalogues", "Non-Profit", "Social", 
//   "Education", "Resources", "Sports", "Financial", "Insurance", "Consultation", "Architectural", "Food", "Medicine", 
//   "Health", "Travel", "Matrimony", "Art", "Communication", "Entertainment", "Environment", "Fashion", "Spa", "Children", 
//   "Craft", "Music", "Navigation", "News"
// ]

const recognitionHead = [
  "Real Estate", "Construction", "Technology", "Automotive", "Non-Profit", "Social", 
  "Education", "Sports", "Financial", "Insurance", "Consultation", "Food", 
  "Health", "Travel", "Communication", "Entertainment", "Fashion", "Music", "Navigation", "News"
]

const recognitionImage = [
  img1, img2, img3, img4, img6,  img7, img8, img10,
  img11, img12, img13, img15, img17, img18, img21, img22, img24, img28, img29, img30
]

const gridContainerVariants = {
  hidden: {
      opacity: 0
  },
  show: {
      opacity: 1,
      transition: {
          staggerChildren: .2,
      }
  }
}

const gridSquareVariants = {
  hidden: {
      opacity: 0,
      y: 20,
  },
  show: {
      opacity: 1,
      transition: {
          y: 0,
          duration: 0.2,
      }
  }
}

const Recognitions = () => {

  return (
    <section id="industries" className="industries container1">
      {/* <h1 className="commonHeading"> Our Recognitions</h1> */}
      {/* <div className="heading-div">
          <h1>
              <hr />Industries
          </h1>
      </div> */}
      <div className="recognition-head">
        <h1>2D Animation Videos for<br/> <span> 40+ Industries</span></h1>
        {/* <h1>Industry <span>Leaders</span> Trust Us for Video Animations, Web<br/>Designs & Development <span>Solutions</span> in<br/> 40+ <span>Industries.</span></h1> */}
        {/* <p>At The Rabbit and Carrot, we prioritize delivering crystal-clear and spotless work to our clients.<br/>
          With a focus on your needs, we offer a wide range of services including website and logo designs,
          video animations,<br/>  and mobile applications. Trust us to fulfill our promises with exceptional results that exceed </p> */}
      </div>
      <div className="recognition-body container1 content-center">
        <ul 
          // variants={gridContainerVariants}
          // initial="hidden"
          // whileInView="show"
          // viewport={{ once: false, amount: 0.5 }}
          >
            {recognitionHead.map((ele, i)=> {
              return (
                <div 
                  // variants={gridSquareVariants}
                  // viewport={{ once: false, amount: 0.5 }}
                  key={i}>
                  <li>
                    <img src={recognitionImage[i]} alt=""/>
                    <h3>{ele}</h3>
                  </li>
                </div>
              )
            })}
        </ul>
      </div>
      <div
        className="button-container content-center">
      <a href="#about-contact"><button className="btn button-touch2"> Explore More
              <span className="line">
                  <span className="circle"></span>
              </span>
      </button></a>
      </div>
    </section>
  )
}

export default Recognitions