import ReactPlayer from "react-player";
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';
// import LazyLoad from "react-lazyload"
import { motion } from "framer-motion";

import "./CaseStudy.css"


import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import neom from "../../../Assets/CaseStudy/neom-logo.webp"
// import australian from "../../../Assets/CaseStudy/australia-logo.webp"
// import employsure from "../../../Assets/CaseStudy/employsure-logo.webp"

import thumbnail1 from "../../../Assets/CaseStudy/neom.webp"
import thumbnail2 from "../../../Assets/CaseStudy/australia.webp"
import thumbnail3 from "../../../Assets/CaseStudy/employsure.webp"

import { useState } from "react";
import { FaRegCirclePlay } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";

const CaseStudies = [
    {
        id: 1,
        title: "NEOM",
        // logo: neom,
        description: "We developed 3D isometric animations to visually depict Neom’s cutting-edge infrastructure and showcase its facilities. These animations were transformed into video brochures, providing an immersive experience tailored for Neom's high-end clientele, perfectly aligning with the brand's futuristic vision.",
        thumbnail: thumbnail1,
        youtube: "https://youtu.be/sp6vU1az9gQ",
    },
    {
        id: 2,
        title: "AUSTRALIAN POST",
        // logo: australian,
        description: "We handled the entire creative process for Australia Post’s 2D corporate video, from character design to script development. Through engaging animation and seamless storytelling, we delivered a visually compelling narrative that conveyed their message in a polished and professional format, perfectly suited for corporate communication.",
        thumbnail: thumbnail2,
        youtube: "https://youtu.be/beYP-UlZod0",
    },
    {
        id: 3,
        title: "EMPLOYSURE",
        // logo: employsure,
        description: "We created an engaging explainer video for Employsure, simplifying complex concepts with dynamic animations and intuitive storytelling. This video strengthened their brand identity and enhanced client outreach.",
        thumbnail: thumbnail3,
        youtube: "https://youtu.be/EOHS_nXdBZ0",
    },
]


export default function CaseStudy() {
    const [playingVideo, setPlayingVideo] = useState(null); // To track the currently playing video

    const handleVideoPlaying = (videoUrl) => {
        setPlayingVideo(videoUrl);
    };

    const closeVideo = () => {
        setPlayingVideo(null);
    };

    const textVarient = {
        initial: {
            y: 100,
            opacity: 0,
        },
        animate: {
            y: 0,
            opacity: 1,
            transition: {
                duration: 1,
                ease: "easeOut",
                staggerChildren: 0.3, // Stagger the children by 0.3s
            },
        },
      };
    
      const childVariants = {
          initial: { y: 50, opacity: 0 },
          animate: { y: 0, opacity: 1, transition: { duration: 0.6 } },
      };

    return (
        <section id="case-studies" className="caseStudy container">
            {/* <div className="heading-div">
                <h1>
                    <hr />Case Studies
                </h1>
            </div> */}
            <h1 className="commonHeading"><span>Case Studies</span></h1>
            <Swiper
              effect={'coverflow'}
              grabCursor={true}
              centeredSlides={true}
              loop={true}
              slidesPerView={1}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 50,
                modifier: 1,
                slideShadows: false, 
              }}
              pagination={{ el: '.swiper-pagination', clickable: true }}
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
                clickable: true,
              }}
              modules={[EffectCoverflow, Pagination, Navigation]}
              className="swiper_container"
            >
                {CaseStudies.map((ele) => {
                    return (
                        <SwiperSlide key={ele.id}>
                            <div className='flexSB'>
                                <div className='left'>
                                    <div className="youtube-box">
                                        {!playingVideo && (
                                            <div className="overlay">
                                                <FaRegCirclePlay />
                                            </div>
                                        )}
                                        {playingVideo === ele.youtube ? (
                                            <div className="video-player">
                                            <ReactPlayer
                                                url={ele.youtube}
                                                playing={true}
                                                controls={true}
                                                width="100%"
                                                height="100%"
                                            />
                                            <button className="close-btn" onClick={closeVideo}>
                                                <IoClose />
                                            </button>
                                            </div>
                                        ) : (
                                            <img
                                            src={ele.thumbnail}
                                            alt={ele.title}
                                            onClick={() => handleVideoPlaying(ele.youtube)}
                                            />
                                        )}
                                    </div>
                                </div>
                                <motion.div className='right'>
                                    <motion.div className="aboutHeading" initial="initial" whileInView="animate" variants={textVarient}>
                                        <motion.div variants={childVariants} className="top">
                                            {/* <img src={ele.logo} alt="2D Animation Company in Dubai" /> */}
                                            <h2>{ele.title}</h2>
                                        </motion.div>
                                        <motion.p variants={childVariants}>{ele.description}</motion.p>
                                    </motion.div>
                                </motion.div>
                            </div>
                        </SwiperSlide>
                    )
                })}
                <div className="slider-controler">
                <div className="swiper-button-prev slider-arrow">
                  <ion-icon name="arrow-forward-outline"></ion-icon>
                </div>
                <div className="swiper-button-next slider-arrow">
                  <ion-icon name="arrow-forward-outline"></ion-icon>
                </div>
                <div className="swiper-pagination"></div>
              </div>
            </Swiper>
        </section>
    )
}