import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import "remixicon/fonts/remixicon.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BrowserRouter as Router } from "react-router-dom";
// import PopupForm from './Components/Common/PopupForm/PopupForm';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Router>
      <App />
      {/* <PopupForm/> */}
    </Router>
  </React.StrictMode>
);