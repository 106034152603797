import React, { useEffect, useRef, useState } from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';


import "./Testimonials.css"

// import next_icon from "../../../Assets/Common/next-icon.png"
// import back_icon from "../../../Assets/Common/back-icon.png"
import company1 from "../../../Assets/Testimonials/dahua.webp"
import company2 from "../../../Assets/Testimonials/tenda.webp"
import company3 from "../../../Assets/Testimonials/rpm.webp"
import company4 from "../../../Assets/Testimonials/become.webp"
import company5 from "../../../Assets/Testimonials/dsl.webp"
import company6 from "../../../Assets/Testimonials/neom.webp"
import company7 from "../../../Assets/Testimonials/pmi.webp"
import company8 from "../../../Assets/Testimonials/huawei.webp"
import company9 from "../../../Assets/Testimonials/reyl.webp"
import company10 from "../../../Assets/Testimonials/toshiba.webp"

import Quote from '../../../Assets/Common/blockquote.svg';

const reviews = [
    {
        id: 1,
        image: company1,
        // name: 'AMAN YAMIN',
        companyName: 'DAHUA TECHNOLOGIES',
        head: "Outstanding Web Development Team!",
        text: 'Rabbit And Carrot delivered beyond our expectations. Their web development expertise ensured our e-commerce site was optimized for performance and sales. The team’s attention to detail and customer-centric approach was impressive throughout the entire process.'
    },
    {
        id: 2,
        image: company2,
        // name: 'AZFER SHEIKH',
        companyName: 'TENDA',
        head: "A Website That Captures Our Brand Perfectly!",
        text: 'Rabbit And Carrot created a stunning website that truly captures our brand’s essence. The design, user interface, and functionality are all top-notch. Their team was incredibly responsive and dedicated to making our vision come to life.'
    },
    {
        id: 3,
        image: company3,
        // name: 'MAHESH KUMAR PATARIA',
        companyName: 'RPM GROUP',
        head: "Seamless Experience from Start to Finish!",
        text: 'Rabbit And Carrot transformed our outdated website into a modern, user-friendly platform that truly reflects our brand’s identity. The team handled everything from concept to launch with professionalism and precision. We couldn\'t be happier with the results!'
    },
    {
        id: 4,
        image: company4,
        // name: "REHAN NAEEM",
        companyName: "BECOME",
        head: "Exceptional Design and Functionality!",
        text: "Working with Rabbit And Carrot was an absolute game changer for our online presence. Their team designed a sleek, intuitive website that not only looks great but performs flawlessly. Our users love the new experience, and so do we!"
    },
    {
        id: 5,
        image: company5,
        // name: "OMAR HAYAT",
        companyName: "DSL",
        head: "Exceptional Digital Media Partnership with Rabbit And Carrot",
        text: "We have recently used Rabbit And Carrot for numerous projects in the UAE including high end stakeholder management. I especially appreciate their willingness to listen to feedback and improve in real time. Thank you."
    },
    {
      id: 6,
      image: company6,
      // name: "OMAR HAYAT",
      companyName: "NEOM",
      head: "Exceptional 2D Animation Partnership",
      text:"We had a tight deadline for a 2D animation project, and Rabbit And Carrot truly delivered. They not only met the deadline, but the quality of the work was outstanding. Their team was professional, efficient, and a pleasure to collaborate with. They went above and beyond, exceeding our expectations.",
    },
    {
      id: 7,
      image: company7,
      // name: "OMAR HAYAT",
      companyName: "PMI",
      head: "A Global Impact with 2D Animation ",
      text:"Rabbit And Carrot created a 2D animation video for us, and it was incredible. The video was played in over 80 countries, and the feedback we received was amazing. They made the whole process easy and delivered a video that told our story perfectly. Their creativity and attention to detail really stood out.",
    },
    {
      id: 8,
      image: company8,
      // name: "OMAR HAYAT",
      companyName: "HUAWEI",
      head: "Stunning Video Production and TV Commercial for Our Phone Launch",
      text:"Rabbit And Carrot helped us create a fantastic video and TV commercial for our new phone launch. They captured the essence of our product perfectly and delivered a final result that looked amazing on screen. The whole process was smooth, and their team was incredibly professional and creative. The response to the campaign was phenomenal!",
    },
    {
      id: 9,
      image: company9,
      // name: "OMAR HAYAT",
      companyName: "REYL",
      head: "Powerful Documentary Interview Partnership",
      text:"Rabbit And Carrot worked with us to create a compelling documentary interview series that truly brought our story to life. Their team was able to capture the essence of our brand and message with sensitivity and professionalism. The entire journey was marked by dedication, creativity, and a shared commitment to bringing our vision to life.",
    },
    {
      id: 10,
      image: company10,
      // name: "OMAR HAYAT",
      companyName: "TOSHIBA",
      head: "Successful Product Launch Campaign",
      text:"Rabbit And Carrot helped us execute an outstanding product launch campaign, including a TV commercial that truly showcased the innovation of our new product. Their creative team understood our vision perfectly, and the commercial received great feedback. We were impressed with how smoothly everything came together.",
    },
  ];

const Testimonials = () => {
  const [screenWidth, setScreenWidth] = useState(0);

  useEffect(() => {
    // Function to update screen size
    const updateScreenSize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Initial size update
    updateScreenSize();

    // Add event listener for screen resize
    window.addEventListener("resize", updateScreenSize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("resize", updateScreenSize);
    };
  }, []);

  return (
    <section id="testimonials" className="testimonial-container">
      {/* <div className="heading-div">
          <h1>
              <hr />Testimonials
          </h1>
      </div> */}
      <div className="title">
        <h1 className="commonHeading"><span>Work Delights</span> & <span>Inspires Clients</span></h1>
        {/* <h3>Delivering Tailored and Distinctive Website Development Services for Your Business at Affordable Prices.</h3> */}
      </div>

      <div className="slider-container">
        <blockquote>
          <img className="top-quote quote" src={Quote} alt="quote" />
          <img className="bottom-quote quote" src={Quote} alt="quote" />
        </blockquote>

        <Splide
          options={{
            perPage: screenWidth <= 1600 ? 1 : (screenWidth <= 2000 ? 2 : 3),
            autoplay: true,
            gap: 20,
            speed: 1000,
            rewind: true,
            rewindByDrag: true,
          }}
        >
          {reviews.map((review) => (
            <SplideSlide key={review.id}>
              <div className="review-img">
                <img src={review.image} alt="2D Animation" />
              </div>
              <div className="content">
                <p className="head">{review.head}</p>
                <p className="text">{review.text}</p>
                <div className="info">
                  <div className="rating">
                    <span className="star">&#9733;</span>
                    <span className="star">&#9733;</span>
                    <span className="star">&#9733;</span>
                    <span className="star">&#9733;</span>
                    <span className="star">&#9734;</span>
                  </div>
                  <p className="company">{review.companyName}</p>
                  {/* <p className="user">{review.name}</p> */}
                </div>
              </div>
            </SplideSlide>
          ))}
        </Splide>
      </div>
    </section>
  );
};

export default Testimonials;