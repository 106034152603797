import { motion } from "framer-motion"
import "./Awards.css"

import award1 from "../../../Assets/Awards/1.webp"
import award2 from "../../../Assets/Awards/2.webp"
import award3 from "../../../Assets/Awards/3.webp"
import award4 from "../../../Assets/Awards/4.webp"
import award5 from "../../../Assets/Awards/5.webp"
import award6 from "../../../Assets/Awards/6.webp"
import award7 from "../../../Assets/Awards/7.webp"


// const gridContainerVariants = {
//   hidden: {
//       opacity: 0
//   },
//   show: {
//       opacity: 1,
//       transition: {
//           staggerChildren: .2,
//       }
//   }
// }

// const gridSquareVariants = {
//   hidden: {
//       opacity: 0,
//       y: 20,
//   },
//   show: {
//       opacity: 1,
//       transition: {
//           y: 0,
//           duration: 0.5,
//       }
//   }
// }

const data = [
  // {
  //   img: award1,
  //   link: "https://www.upwork.com/agencies/734510642592346112/"
  // },
  {
    img: award2,
    link: "https://www.designrush.com/agency/profile/rabbit-and-carrot"
  },
  {
    img: award3,
    link: "https://clutch.co/profile/rabbit-carrot"
  },
  {
    img: award4,
    link: "https://clutch.co/profile/rabbit-carrot"
  },
  {
    img: award5,
    link: "https://www.bark.com/en/gb/company/rabbit-and-carrot-llc/2MdZg9"
  },
  {
    img: award6,
    link: "https://www.yelp.com/biz/rabbit-and-carrot-wayne"
  },
  {
    img: award7,
    link: "https://www.goodfirms.co/company/rabbit-and-carrot-llc"
  }
]

const imageVariants = {
  hover: {
    scale: 1.05, // Slightly increase the size on hover
    transition: {
      duration: 0.3,
    },
  },
  initial: {
    x: 100,
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1,
      // repeat: Infinity,
      ease: "easeInOut",
    },
  },
  exit: {
    x: [0, 10, 0], // Small up and down movement
    transition: {
      duration: 2,
      repeat: Infinity,
      ease: "easeInOut",
    },
  }
};

function Awards() {
    return (
      <section className="container1 awards">
         {/* <div className="heading-div">
            <h1>
                <hr />Awards
            </h1>
        </div> */}
          <h1 className="commonHeading"><span>Awards & Recognitions</span></h1>
              <div 
                // variants={gridContainerVariants}
                // initial="hidden"
                // whileInView="show"
                // viewport={{ once: false, amount: 0.5 }}
                className="awards-body">
                  {/* <Slider {...settings}> */}
                      {data.map((d, i) => (
                          <motion.div 
                            // variants={gridSquareVariants}
                            // viewport={{ once: false, amount: 0.5 }}
                            key={d.img} 
                            className="awards-image"
                            variants={imageVariants}
                            initial="initial"
                            whileInView="animate"
                            exit="exit"
                            whileHover="hover"
                            >
                              <a href={d.link}>
                                <img src={d.img} alt="2D Animation" className={`award-image-${i+1}`} />
                              </a>
                          </motion.div>
                      ))} 
                  {/* </Slider> */}
              </div>
      </section>
    );
}

export default Awards;