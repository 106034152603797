import React from 'react'

import "./Footer1.css"

export default function Footer1() {
  return (
    <footer className="footer1">
        <p className="copyRight">Rabbit And Carrot © 2009 - 2024. All Rights Reserved.</p>
    </footer>
  )
}
